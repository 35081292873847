import Utils from '../../lib/utils';
import * as types from '../actions/types'; 
import LocalizedStrings from 'react-localization';
import en from '../languages/en'; 
import zeros from '../languages/zeros'; 

export const setCustomStrings = (strings, customStringsAssigned=true) => {
  return {
    type: types.SET_STRINGS,
    strings,
    customStringsAssigned
  };
};

export const setCustomTerms = (customTerms) => {
  return (dispatch, getState) => {
    const { strings, customStringsAssigned } = getState().localization;
    if (customStringsAssigned) {
      return;
    }
    let customStrings = strings;
    customTerms.forEach(term => {
      customStrings = Utils.deepValueReplace(customStrings, term.OriginalTerm, term.CustomTerm);
    });
    dispatch(setCustomStrings(customStrings));
  }
}

export const resetCustomTerms = () => {
  return (dispatch, getState) => {
    const { customStringsAssigned } = getState().localization;
    if (!customStringsAssigned) {
      return;
    }
    const customStrings = new LocalizedStrings({en, zeros});
    dispatch(setCustomStrings(customStrings, false));
  }
}