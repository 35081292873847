import _ from 'lodash';
import * as types from './types';
import Api from '../../../../lib/api';
import * as customActions from "../../../../localization/custom";
import { CERTFOCUS_ANALYTICS_PATH, PREQUAL_ANALYTICS_PATH } from '../../../../lib/pathConstants';
import { CF_ADMIN_ROLE_ID, PQ_ADMIN_ROLE_ID } from '../../../../lib/appConstants';
import { removeTokenAndSession } from '../../../../utils/index';

export const setLoginProcessing = (processing) => {
  return {
    type: types.SET_LOGIN_PROCESSING,
    processing
  };
};

export const setCredentials = (credentials) => {
  return {
    type: types.SET_CREDENTIALS,
    credentials
  };
};

export const setErrorCredentials = (msj) => {
  return {
    type: types.SET_ERROR_CREDENTIALS,
    msj
  }
};

export const setIsLoggedIn = (isLoggedIn) => {
  return {
    type: types.SET_ISLOGGEDIN,
    isLoggedIn
  };
};

export const setLoginExtraMsj = (extraMessage) => {
  return {
    type: types.SET_LOGIN_EXTRA_MSG,
    extraMessage
  };
};

export const setAuthToken = (authToken) => {
  return {
    type: types.SET_AUTH_TOKEN,
    authToken: authToken
  };
};

export const setProfile = (profile) => {
  return {
    type: types.SET_USER_PROFILE,
    profile
  };
};

export const sendCredentials = (credentials, history, from) => {
  //console.log(`from.pathname == "/dashboard" => ${from.pathname == "/dashboard"}`)
  const loginRoute = from.pathname === '/sso' ? '/dashboard' : '/login';

  return (dispatch, getState) => {
    let actionStrings = getState().localization.strings.auth.login.actions;

    dispatch(setLoginProcessing(true));
    dispatch(setCredentials(credentials));
    return Api.post(
      `users/gettoken`,
      {
        email: credentials.username,
        password: credentials.password,
        sso: credentials.sso,
      }
    )
    .then(response => {
      const errors = {
        10008: actionStrings.error10008,
        10009: actionStrings.error10009
      };
      const { success, data } = response.data;
      //console.log('LOGIN RESPONSE', response);
      if(success) {
        dispatch(setProfile(data.profile))
        localStorage.setItem('auth-token', data.token)
        if(credentials.remember) {
          localStorage.setItem('auth-token', data.token);
          localStorage.setItem('remember', true);
        } else {
          //localStorage.removeItem('auth-token');
          localStorage.removeItem('remember');
        }
        dispatch(setProfile(data.profile));
        //The refresh button causes profile state to be empty, that's why we Use localStorage to save these items
        localStorage.setItem('pqRoleId', data.profile.RoleID);
        localStorage.setItem('multipleHiringClients', data.profile.multipleHiringClients);
    
        let system = 'pq'
        if (data.profile.CFRole) {
          system = 'cf';
        }
        dispatch(setCurrentSystem(system));
        localStorage.setItem('currentSystem', system)

        dispatch(setIsLoggedIn(true));
        dispatch(setAuthToken(data.token));
        dispatch(setLoginExtraMsj(''));
        dispatch(customActions.resetCustomTerms());
        //Keep SSO user logged in
        if(data.profile.SSO !== null) {
          dispatch(setProfile(data.profile));
          localStorage.setItem('SSO', data.profile.SSO);
          localStorage.setItem('auth-token', data.token);
        }

        // Custom Terms
        if (data.profile.customTerms && data.profile.customTerms.length > 0) {
          dispatch(customActions.setCustomTerms(data.profile.customTerms));
        }

        let redirectUrl = '';
        const IsPrequalRole = _.get(data, 'profile.Role.IsPrequalRole');
        const IsHCRole = _.get(data, 'profile.Role.IsHCRole');
        const IsSCRole = _.get(data, 'profile.Role.IsSCRole');
        const isPQRole = _.get(data, 'profile.RoleID');
        const isCFRole = _.get(data, 'profile.CFRoleId');
        const isBIAccess = _.get(data, 'profile.BIAccess');
        // Disabling PQ Role to have analytics route for now as asked VE-5395
        if(isBIAccess && !IsPrequalRole && (isPQRole !== PQ_ADMIN_ROLE_ID && isCFRole !== CF_ADMIN_ROLE_ID)){
          if(isPQRole && isCFRole){
            redirectUrl= CERTFOCUS_ANALYTICS_PATH;
          }else if(isPQRole){
            redirectUrl= PREQUAL_ANALYTICS_PATH;
          }else if(isCFRole){
            redirectUrl= CERTFOCUS_ANALYTICS_PATH;
          }else{
            redirectUrl= '/hiringclients/';
          }
        }
        else if (IsPrequalRole) {
          redirectUrl= '/tasks';
          if (data.profile.RoleID == 1 || data.profile.RoleID == 26) {
            redirectUrl= '/hiringclients/';
          }
        } else if (IsHCRole) {
          let hcId = ""

          if (data.profile.multipleStrictHCs && data.profile.multipleStrictHCs.length && data.profile.multipleStrictHCs.length > 1) {
            redirectUrl= '/hiringclients/';
          } else if (data.profile.relatedHc && data.profile.relatedHc.id) {
            hcId = data.profile.relatedHc.id;
            redirectUrl = `/hiringclients/${hcId}`;
          }

        } else if (IsSCRole) {
          let scId = ""
          if (data.profile.FirstSubcontractorId && data.profile.FirstSubcontractorId) {
            scId = data.profile.FirstSubcontractorId;
          }
          redirectUrl = `/subcontractors/${scId}`;
        }

        if (!IsSCRole && from.pathname !== "/profile" && from.pathname !== "/sso") {
          redirectUrl = from.pathname;
        }



        const url = data.profile.MustRenewPass ? '/reset' : redirectUrl

        //console.log('redirectUrl in login/actions = ', redirectUrl)
        //console.log('url = ', url)
        //console.log('REDIRECT', url);

        history.push(url);

      } else {
        //console.log('ERROR');
        let errorMsj = errors[data.errorCode] || actionStrings.errorDefault;
        dispatch(setErrorCredentials(errorMsj));
        history.push(loginRoute);
      }
      dispatch(setLoginProcessing(false));
    })
    .catch(error => {
      dispatch(setLoginProcessing(false));
      dispatch(setErrorCredentials(actionStrings.errorDefault));
      history.push(loginRoute);
    });
  };
};

export const getProfile = () => {
  return (dispatch, getState) => {
    let accessTimeRecorded = sessionStorage.getItem('accessTimeRecorded');
    if (!accessTimeRecorded) {
      sessionStorage.setItem('accessTimeRecorded', 'true');
    }
    let actionStrings = {
      ...getState().localization.strings.auth.forgot.actions,
      ...getState().localization.strings.auth.login.actions
    };
    const token = getState().login.authToken;

    return Api.get(`users/profile${!accessTimeRecorded ? '?recordAccessTime=true' : ''}`, token)
    .then(response => {
      let errorMsj = '';
      const {success, data } = response.data;

      if(success) {
        if(data.profile && data.profile.Id)localStorage.setItem('user-id', data.profile.Id);
        //console.log('getProfile data.profile = ', data.profile)
        dispatch(setProfile(data.profile));
        /*
        if (data.profile.CFRole) {
          dispatch(setCurrentSystem('cf'));
        } else {
          dispatch(setCurrentSystem('pq'));
        }
        */
        // Custom Terms
        if (data.profile.customTerms && data.profile.customTerms.length > 0) {
          dispatch(customActions.setCustomTerms(data.profile.customTerms));
        }
      }
      else {
        switch(data.errorCode) {
          case 10005:
            errorMsj = actionStrings.error10005;
            break;
          case 10006:
            errorMsj = actionStrings.error10006;
            break;
          case 10007:
            errorMsj = actionStrings.error10007;
            break;
          case 10011:
            errorMsj = actionStrings.error10011;
            break;
          default:
            errorMsj = actionStrings.errorDefault;
            break;
        }
        removeTokenAndSession();
        dispatch(setIsLoggedIn(false));
        dispatch(setAuthToken(""));
        dispatch(setErrorCredentials(errorMsj));
      }
    })
    .catch(error => {
      dispatch(setErrorCredentials(actionStrings.errorConnection));
    });
  };
};

export const setCurrentSystem = (system) => {
  return {
    type: types.SET_CURRENT_SYSTEM,
    payload: system,
  }
}
