import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Utils from '../../../../../lib/utils';

import { CF_ADMIN_ROLE_ID } from '../../../../../lib/appConstants';
import * as commonActions from '../../../../common/actions';
import * as actions from './actions';

import './holderSettings.css';

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {
        CFCompliantAndAccepted: '',
        CFNonDateFields: '',
        CFRevisedDates: '',
        CFRevisedWordingPresent: '',
        CFApplyingCertificates: '',
        AllowDocumentAutoProcess: null,
        AllowURLDocumentUploads: null,
      },
      havePermision: false,
      onSaveLoader: false
    };
  }

  componentDidMount() {
    const { commonActions, common, holderId, actions } = this.props;

    if (common.certificateOptions.length <= 0) {
      commonActions.fetchCertificateOptions();
    }

    if (common.dataEntryOptions.length <= 0) {
      commonActions.fetchDataEntryOptions();
    }

    actions.fetchSettings(holderId, (settings) => {
      if (!_.isEmpty(settings)) {
        if(this.props.login.profile.CFRoleId !== CF_ADMIN_ROLE_ID || process.env.REACT_APP_ALLOW_DOCUMENT_AUTO_PROCESS !== 'true'){
          delete settings.AllowDocumentAutoProcess
        }
        this.setState({ settings });
      }
    });
    
    const havePermision = this.props.login.rolesAccessPermissions
      .find(x => x.MasterTab === 'holder_settings' && x.SectionTab === 'set_data_entry_rules');
    if (havePermision) {
      this.setState({ havePermision: true });
    }
  }

  onSettingChange = (e) => {
    const { settings } = this.state;
    const { name, value } = e.target;
    const { holderId } = this.props;
    const newSettings = { ...settings, [name]: Number(value) };

    this.props.actions.sendSettings({ ...newSettings, holderId }, () => {
      this.setState({ settings: newSettings });
    });
  }

  onAllowAutoProcessChange = (e) => {
    const { settings } = this.state;
    const { holderId } = this.props;
    const newSettings = { ...settings, AllowDocumentAutoProcess: e.target.checked};
    this.props.actions.sendSettings({ ...newSettings, holderId }, () => {
      this.setState({ settings: newSettings });
    });
  }

  onSaveHawlIExtractionLimitChange = () => {
    this.setState({onSaveLoader: true})
    const { settings } = this.state;
    const { holderId } = this.props;
    const newSettings = { ...settings, hExtractDailyProcessLimit: parseInt(this.hawkIExtractionLimitInput.value)};
    this.props.actions.sendSettings({ ...newSettings, holderId }, () => {
      this.setState({ settings: newSettings, onSaveLoader: false});
    });
  };

  onAllowURLDocumentUploadChange = (e) => {
    const { settings } = this.state;
    const { holderId } = this.props;
    const newSettings = { ...settings, AllowURLDocumentUploads: e.target.checked};
    this.props.actions.sendSettings({ ...newSettings, holderId }, () => {
      this.setState({ settings: newSettings });
    });
  }

  renderSetting = (setting, idx) => {
    const { label, name, value, options } = setting;
    
    return (
      <div key={idx} className="admin-form-field-wrapper">
        <label htmlFor={name}>{`${label}:`}</label>
        <div className="select-wrapper">
          <select
            disabled={this.props.holderSettings.fetchingSettings || !this.state.havePermision}
            value={value || 1}
            name={name}
            onChange={this.onSettingChange}
          >
            {options.map(this.renderOptions)}
          </select>
        </div>
      </div>
    );
  }

  renderOptions = (opt, idx) => {
    return <option value={opt.value} key={idx}>{opt.label}</option>
  }

  render() {
    const {
      labelDataEntryRules,
      labelRevisedWordingPresent,
      labelCompliantAndAccepted,
      labelRevisedDates,
      labelNonDateFields,
      labelApplyingCertificates,
      allowDocumentAutoProcess,
      allowURLDocumentUploads,
      hawkiExtractDailyLimit
    } = this.props.local.strings.holderSettings;

    const {
      CFRevisedWordingPresent,
      CFCompliantAndAccepted,
      CFRevisedDates,
      CFNonDateFields,
      CFApplyingCertificates,
      AllowDocumentAutoProcess,
      AllowURLDocumentUploads,
      hExtractDailyProcessLimit
    } = this.state.settings;

    const dataEntryRuleOptions = Utils.getOptionsList(null, this.props.common.dataEntryOptions, 'OptionName', 'OptionId');
    const applyingCertificatesOptions = Utils.getOptionsList(null, this.props.common.certificateOptions, 'OptionName', 'OptionId');

    const DERSettings = [
      {
        label: labelRevisedWordingPresent, name: 'CFRevisedWordingPresent',
        value: CFRevisedWordingPresent, options: dataEntryRuleOptions
      },
      {
        label: labelCompliantAndAccepted, name: 'CFCompliantAndAccepted',
        value: CFCompliantAndAccepted, options: dataEntryRuleOptions
      },
      {
        label: labelRevisedDates, name: 'CFRevisedDates',
        value: CFRevisedDates, options: dataEntryRuleOptions
      },
      {
        label: labelNonDateFields, name: 'CFNonDateFields',
        value: CFNonDateFields, options: dataEntryRuleOptions
      },
    ];
    const ACSetting = {
      label: labelApplyingCertificates, name: 'CFApplyingCertificates',
      value: CFApplyingCertificates, options: applyingCertificatesOptions
    };

    if (this.props.holderSettings.errorSettings) {
      return (
        <div className="holder-settings mt-3">
          <div className="settings-error">
            {this.props.holderSettings.errorSettings}
          </div>
        </div>
      );
    }

    const cfRoleId = this.props.login.profile.CFRoleId;

    return (
      <div className="add-item-view add-entity-form-small">
        <section className="white-section holder-settings">
          <div className="add-item-form-subsection">
            <div className="add-item-header">
              <h1>{labelDataEntryRules}</h1>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-5">
                  <div className="entity-info-form">
                    {DERSettings.map(this.renderSetting)}
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-5">
                  <div className="entity-info-form">
                    {this.renderSetting(ACSetting,0)}
                  </div>
                </div>
              </div>
            </div>
            {
              cfRoleId === CF_ADMIN_ROLE_ID && process.env.REACT_APP_ALLOW_DOCUMENT_AUTO_PROCESS === 'true' &&
                (
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-5">
                        <div className="entity-info-form">
                          <div className='row' style={{marginLeft:0}}>
                            <div className="admin-form-field-wrapper">
                              <label htmlFor='allowDocumentAutoProcess'>{allowDocumentAutoProcess}:</label>
                              <div className="checkbox-wrapper">
                                <input
                                  type="checkbox"
                                  name="allowDocumentAutoProcess"
                                  className='agree pretty-checkbox'
                                  onChange={this.onAllowAutoProcessChange}
                                  checked={AllowDocumentAutoProcess}
                                />
                              </div>
                            </div>
                            {/* {
                              AllowDocumentAutoProcess && (
                                <div className='admin-form-field-wrapper hawkIExtractionLimit'>
                                  <label className='label' htmlFor='hawkIExtractionLimit'>{hawkiExtractDailyLimit}:</label>
                                  <input
                                    type="number"
                                    name="hawkIExtractionLimit"
                                    defaultValue={hExtractDailyProcessLimit}
                                    ref={(input) => this.hawkIExtractionLimitInput = input}
                                  />
                                  <button 
                                    type='button' 
                                    disabled = {this.state.onSaveLoader}
                                    className= {`save-btn ${this.state.onSaveLoader && 'save-btn-disabled'}`}
                                    onClick={this.onSaveHawlIExtractionLimitChange}
                                  >
                                    {
                                      this.state.onSaveLoader ? "Saving" : "Save" 
                                    }
                                  </button>
                                </div>
                              )
                            } */}
                          </div>
                          <div className="admin-form-field-wrapper">
                            <label htmlFor='allowURLDocumentUploads'>{allowURLDocumentUploads}:</label>
                            <div className="checkbox-wrapper">
                              <input
                                type="checkbox"
                                name="allowURLDocumentUploads"
                                className='agree pretty-checkbox'
                                onChange={this.onAllowURLDocumentUploadChange}
                                checked={AllowURLDocumentUploads}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            }
          </div>
          <div className="settings-error">
            {this.props.holderSettings.errorSendSettings}
          </div>
        </section>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    holderSettings: state.holderSettings,
    local: state.localization,
    common: state.common,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
