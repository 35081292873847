import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';
import Api from '../../../../../lib/api';
import {Button, createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import renderHTML from 'react-render-html';
import { Visibility } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import moment from 'moment'
import * as actions from '../../actions';
import Utils from "../../../../../lib/utils";
import _, {debounce} from 'lodash';
import * as commonActions from "../../../../common/actions";
import {toast, ToastContainer} from "react-toastify";

const EmailActivity = props => {
  const { insuredId } = props;

  const [data, setData] = useState([]);
  const [showMailSent, setShowMailSent] = useState(false);
  const [mailBody, setMailBody] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [orderDirection, setOrderDirection] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({});
  const [activityNames, setActivityNames] = useState([]);
  const debounceFn = useMemo(() => debounce(updateSearchText, 1000), []);

  const { from, to, date, template, activity, holder, projectNumber, project, actions } = props.locale.table.columns;

  const columns = [
    {
      name: 'fromAddress',
      label: from,
      options: {
        filterList: props.filter[0] || '',
      }
    },
    {
      name: 'toEmail',
      label: to,
      options: {
        filterList: props.filter[1] || '',
      }
    },
    {
      name: 'date',
      label: date,
      options: {
        filterList: props.filter[2] || '',
      }
    },
    {
      name: 'template',
      label: template,
      options: {
        filterList: props.filter[3] || '',
      }
    },
    {
      name: 'activity',
      label: activity,
      options: {
        filterType: 'dropdown',
        filterList: props.filter[4] || '',
        filterOptions: {
          names: activityNames
        },
      }
    },
    {
      name: 'holder',
      label: holder,
      options: {
        filterList: props.filter[5] || '',
      }
    },
    {
      name: 'projectNumber',
      label: projectNumber,
      options: {
        filterList: props.filter[6] || '',
      }
    },
    {
      name: 'projectName',
      label: project,
      options: {
        filterList: props.filter[7] || '',
      }
    },
    {
      name: "",
      options: {
        display: false,
        filter: true,
        filterList: props.filter[8] || '',
        filterType: 'checkbox',
        filterOptions: {
          names: ['Show workflow modifications'],
        },
      },
    },
    {
      name: 'mailBody',
      label: actions,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              onClick={() => openModal(value)}
              style={{ color: (value) ? '#8CC739' : '#F00' }}
            ><Visibility/>View
            </a>
          );
        }
      }
    },
    {
      name: 'documentList',
      label: 'Documents',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <React.Fragment>
              {value.map((item) => {
                return (
                  <a
                    href={item.link}
                    target="_blank"
                  ><Visibility/>{item.name}</a>
                );
              })
              }
            </React.Fragment>
          );
        },
      }
    },
  ];

  const applyFilter = applyNewFilters => {
    let filterList = applyNewFilters();
  }

  useEffect( () => {
    let query = Utils.getFetchQuery(orderBy, pageNumber, orderDirection, pageSize);
    query.insuredId = insuredId;
    if(searchText && searchText !== ''){
      query.searchTerm = searchText
    }
    if(filters){
      query = {...query, ...filters};
    }
    let urlParameters = `?${Object.keys(query).map(i => `${i}=${query[i]}`).join('&')}`;

    fetchMailActivity(urlParameters)
  }, [pageNumber, pageSize, orderBy, orderDirection, searchText, filters])

  const options = {
    download: false,
    filter: true,
    filterType: 'textField',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    count: count,
    page: pageNumber,
    responsive: 'standard',
    fixedHeader: true,
    tableBodyMaxHeight: '410px',
    jumpToPage: true,
    confirmFilters: true,
    serverSide: true,
    setTableProps: () => {
      return {
        size: 'small',
      };
    },
    onColumnSortChange: (changedColumn, direction) => {
      setOrderBy(changedColumn)
      setOrderDirection(direction)
      setPageNumber(0)
    },
    setFilterChipProps: (colIndex, colName, filterValue) => {
      if(columns[colIndex] && columns[colIndex].hasOwnProperty('label')){
        return { label: `${columns[colIndex]['label'].toUpperCase()} ${filterValue}` };
      }
    },
    onFilterConfirm: (filterList) => {
      const filterObj = {};
      columns.forEach((item, key) => {
        if(filterList[key].length > 0){
          if(item.name){
            filterObj[item.name] = filterList[key][0]
          }
          if(filterList[key][0] == 'Show workflow modifications'){
            filterObj['showWorkflowModifications'] = true
          }
        }
      })
      setFilters(filterObj)
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button variant="contained" onClick={() => applyFilter(applyNewFilters)}>Apply Filters</Button>
        </div>
      );
    },
    onFilterChange: (changedColumn, filterList, type) => {
      clearTimeout(handleFilterTimeout);
      handleFilterTimeout = setTimeout( () => {
        if(type === 'chip'){
          const filterObj = {};
          columns.forEach((item, key) => {
            if(filterList[key].length > 0){
              filterObj[item.name] = filterList[key][0]
            }
          })
          setFilters(filterObj)
        }
      },1000);
    },

    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setPageNumber(tableState.page + 1)
          break;
        case 'changeRowsPerPage':
          setPageSize(tableState.rowsPerPage)
          setPageNumber(0)
          break;
        case 'search':
          debounceFn(tableState.searchText)
          break;
        default:

      }
    }
  };

  let handleFilterTimeout = null;

  function updateSearchText(inputValue) {
    setSearchText(inputValue)
    setPageNumber(0)
  }

  const openModal = (data) => {
    if (!data) return false;
    setMailBody(data);
    setShowMailSent(true);
  };
  const closeModal = () => {
    setShowMailSent(false);
  };

  const fetchMailActivity = async (params) => {
    props.commonActions.setLoading(true);
    const { authToken } = props.login;
    const urlQuery = `cf/getEmailActivity${params}`;
    await Api.get(urlQuery, authToken)
      .then(async response => {
        let { success, data } = response.data;
        await data.emailActivity.map(v => {
          v.date = moment(v.date).utc().format('MM/DD/YYYY hh:mm:ss A')
          v.toEmail = v.toEmail && v.toEmail != 'undefined' ? v.toEmail : ''
          if(!v.template){
            v.template = v.WorkflowName
            v.activity = 'Workflow Modified'
          }
          return v;
        })
        if (success){
          setData(data.emailActivity)
          setCount(data.totalCount)
        }
        props.commonActions.setLoading(false);
      })
      .catch(() => {
        props.commonActions.setLoading(false);
        toast.error('Something went wrong, Please try again letter.', {
          position: toast.POSITION.TOP_RIGHT
        })
      });
  };

  const fetchMailActivityNames = async () => {
    const { authToken } = props.login;
    const urlQuery = `cf/getActivityNames`;
    await Api.get(urlQuery, authToken)
      .then(async response => {
        let { success, data } = response.data;
        if (success){
          setActivityNames(data)
        }
      })
      .catch(() => {
        //console.log('could not execute request');
      });
  }

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiGrid: {
          'spacing-xs-4': {
            '& > $item': {
              padding: 5
            }
          },
        },
        MuiTableCell: {
          head: {
            color: '#085DAD',
            fontSize: 14,
            textTransform: 'uppercase',
            padding: 10,
            fontWeight: 700,
          },
        },
        MUIDataTableFilter: {
          gridListTile: {
            marginTop: 0,
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
        MuiTypography: {
          h6: {
            color: '#2E5965',
            fontSize: 18,
            textTransform: 'uppercase',
            fontWeight: 700,
          }
        },
        MUIDataTableBodyCell: {
          root: {
            '& > a': {
              cursor: 'pointer',
              display: 'block',
              color: '#8CC739',
              '&:hover': {
                textDecoration: 'underline',
              },
              '& > svg': {
                fontSize: 16,
                marginRight: 5
              }
            },

          }
        }
      },
    });

  useEffect(() => {
    (async function loadData () {
      await fetchMailActivityNames()
    })();
  }, []);

  const { title } = props.locale.table;

  return (
    <div className="admin-view-body">
      <Modal
        show={showMailSent}
        onHide={closeModal}
        className="add-item-modal add-entity-small">
        <Modal.Body>
          {renderHTML(`${_.unescape(mailBody)}`)}
        </Modal.Body>
      </Modal>
      <ToastContainer/>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>

  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.localization.strings.mailActivity,
    login: state.login,
    filter: state.mailActivity.filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch)
  };
};

EmailActivity.propTypes = {
  insuredId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailActivity);
